import { combineReducers, Reducer } from "redux";
import { USER_LOGOUT } from "../../constantes/redux";

// all reducers
import {
  LivePricerReducer,
  EuroDollarReducer,
  InvalidateDataSeetingReducer,
} from "./others";
import userReducer from "./user";
import managerReducer from "./manager";
import assetDataReducer from "./asset";
import accountManagerReducer from "./account_manager";
import { userManagementReducer, userManagedReducer } from "./userManagement";

const allreducers: any = combineReducers({
  user: userReducer,
  userManagement: userManagementReducer,
  userManaged: userManagedReducer,
  invalidateDataSeeting: InvalidateDataSeetingReducer,
  manager: managerReducer,
  accountManager: accountManagerReducer,
  euroDollar: EuroDollarReducer,
  livePrice: LivePricerReducer,
  assetData: assetDataReducer,
});

const rootReducer: Reducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    localStorage.removeItem("persist:root");
    localStorage.clear();
    state = undefined;
  }
  return allreducers(state, action);
};

export default rootReducer;
