import color from "../constantes/color";
// import CircularStdBold from "../assets/fonts/CircularStdBold.ttf";
import MontserratRegular from "./../assets/fonts/Montserrat-Regular.ttf";
import { createTheme, colors, responsiveFontSizes } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: color.bluePrimary,
    },
    success: {
      main: color.green,
    },
    error: {
      main: color.red,
    },
    info: {
      main: color.info,
    },
    warning: {
      main: colors.yellow[500],
    },
    background: {
      default: color.grey,
    },
    text: {
      primary: color.black,
      secondary: colors.grey[500],
    },
  },
  typography: {
    fontFamily: "Montserrat,sans-serif",
    fontSize: 12,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Montserrat';
          color: ${colors.grey[200]};
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url(${MontserratRegular}) format('truetype');
        }
        `,
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }: any) => ({
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              backgroundColor: color.blueSecondary,
              color: color.white,
              textTransform: "none",
              hover: {
                backgroundColor: color.blueSecondary,
                color: color.white,
              },
            }),
        }),
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: color.grey,
          border: "none",
          boxShadow: "none",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "1rem",
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
