import { CircularProgress, CircularProgressProps } from "@mui/material";

export default function Loading({
  size = 20,
  color = "primary",
}: {
  size?: number;
  color?: CircularProgressProps["color"];
}) {
  return <CircularProgress size={size} color={color} thickness={3} />;
}
