import {
  extraRoutes,
  otherRoutes,
  clientManagementRoutes,
  clientManagementRoutesSelected,
  userSelectedAndUserNoManagerOnlyRoutes,
} from "./route";
import React, { useEffect } from "react";
import { selectUser } from "../redux/selector";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import { SET_TOGGLE_EURO_DOLLAR } from "../constantes/redux";
import { useGetCurrency } from "../hook/useGetCurrencyEurUsdt";

// components
const Layout = React.lazy(() => import("../Layout"));
const Login = React.lazy(() => import("../views/Login"));
// const ManagedUser = React.lazy(() => import("../views/ManagedUser"));
// const ManageClientAndTeam = React.lazy(
//   () => import("../views/ManageClientAndTeamAccount")
// );

export default function Navigation() {
  const userConnected = useSelector(selectUser).connected;

  const currency = useGetCurrency();

  const dispacth = useDispatch();

  useEffect(() => {
    if (currency.isSuccess) {
      dispacth({
        type: SET_TOGGLE_EURO_DOLLAR,
        payload: { valueEuroDollar: 1 / parseFloat(currency.data.price) },
      });
    }
  }, [currency, dispacth]);

  return (
    <Routes>
      <Route
        path="/"
        element={userConnected ? <Navigate to="/dashboard" /> : <Login />}
      />
      <Route
        path="/*"
        element={
          <Layout>
            <Routes>
              {clientManagementRoutes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    userConnected ? <route.element /> : <Navigate to="/" />
                  }
                />
              ))}
              {clientManagementRoutesSelected.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    userConnected ? <route.element /> : <Navigate to="/" />
                  }
                />
              ))}

              {otherRoutes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    userConnected ? <route.element /> : <Navigate to="/" />
                  }
                />
              ))}
              {extraRoutes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    userConnected ? <route.element /> : <Navigate to="/" />
                  }
                />
              ))}
              {userSelectedAndUserNoManagerOnlyRoutes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    userConnected && userConnected ? (
                      <route.element />
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
              ))}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Layout>
        }
      />
    </Routes>
  );
}
