import React from "react";
import { RouteType } from "../types";

// Icons
import PaidIcon from "@mui/icons-material/Paid";
import FlakyIcon from "@mui/icons-material/Flaky";
import GradeIcon from "@mui/icons-material/Grade";
import SettingsIcon from "@mui/icons-material/Settings";
import DashboardIcon from "@mui/icons-material/Dashboard";

// Components
const Seeting = React.lazy(() => import("../views/Seeting"));
const ExitPlan = React.lazy(() => import("../views/ExitPlan"));
const Dashboard = React.lazy(() => import("../views/Dashboard"));
const MyProfile = React.lazy(() => import("../views/MyProfile"));
const ManagedUser = React.lazy(() => import("../views/ManagedUser"));
const Transaction = React.lazy(() => import("../views/Transaction"));
const CryptoRating = React.lazy(() => import("../views/CryptoRating"));
const ManageClientAndTeam = React.lazy(
  () => import("../views/ManageClientAndTeamAccount")
);
const DetailsExitPlan = React.lazy(
  () => import("./../features/ExitPlan/Details")
);
// Routes visible on sidebar
const clientManagementRoutes: RouteType[] = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: DashboardIcon,
    element: Dashboard,
  },
  {
    name: "Transactions",
    path: "/transaction",
    icon: PaidIcon,
    element: Transaction,
  },
];

// Routes visible on sidebar
const clientManagementRoutesSelected: RouteType[] = [
  {
    name: "Settings",
    path: "/settings",
    icon: SettingsIcon,
    element: Seeting,
  },
];

// Routes visible on sidebar
const otherRoutes: RouteType[] = [
  {
    name: "Crypto Rating",
    path: "/crypto-rating",
    icon: GradeIcon,
    element: CryptoRating,
  },
];

const userSelectedAndUserNoManagerOnlyRoutes: RouteType[] = [
  {
    name: "Exit Plan",
    path: "/exit-plan",
    icon: FlakyIcon,
    element: ExitPlan,
  },
  {
    name: "Exit Plan Details",
    path: "/exit-plan/details/:id",
    icon: FlakyIcon,
    element: DetailsExitPlan,
  },
];

// Routes no visible on sidebar
const extraRoutes: RouteType[] = [
  {
    path: "/my-profile",
    element: MyProfile,
  },
  {
    path: "/managed-user",
    element: ManagedUser,
  },
  {
    path: "/manage-client-and-team",
    element: ManageClientAndTeam,
  },
];

export {
  otherRoutes,
  extraRoutes,
  clientManagementRoutes,
  clientManagementRoutesSelected,
  userSelectedAndUserNoManagerOnlyRoutes,
};
