import { allStateReduxType } from "../types/redux";

export const selectUser = (state: allStateReduxType) => state.user;
export const selectUserManagement = (state: allStateReduxType) =>
  state.userManagement;
export const selectUserManaged = (state: allStateReduxType) =>
  state.userManaged;
export const selectInvalidateDataSeeting = (state: allStateReduxType) =>
  state.invalidateDataSeeting;
export const selectManager = (state: allStateReduxType) => state.manager;
export const selectAccountManager = (state: allStateReduxType) =>
  state.accountManager;
export const selectEuroDollar = (state: allStateReduxType) => state.euroDollar;
export const selectLivePrice = (state: allStateReduxType) => state.livePrice;
export const selectAssetData = (state: allStateReduxType) => state.assetData;
