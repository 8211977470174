import {
  UserManaged,
  UserManagement,
  UserManagedActionType,
  UserManagementActionType,
} from "../../types/user";
import { SETUSERMANAGEMENT, SETUSERMANAGED } from "../../constantes/redux";

const initialState: UserManagement = {
  userSelected: null,
};

const initStateUserManaged: UserManaged = {
  data: null,
};

export function userManagementReducer(
  state = initialState,
  action: UserManagementActionType
) {
  switch (action.type) {
    case SETUSERMANAGEMENT:
      return {
        ...state,
        userSelected: action.payload.userSelected,
      };
    default:
      return state;
  }
}

export function userManagedReducer(
  state = initStateUserManaged,
  action: UserManagedActionType
) {
  switch (action.type) {
    case SETUSERMANAGED:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
}
